$fontColorDefault:#5d5955;
$orange: #ff6d00;
$darkpetrol:#00504b;
$petrol:#048174;
$gelb: #f0dc00;
$gruen: #c7d300;
$blau: #b9e0de;
$violett: #5c004e;

$breakXL: 1550px;
$breakL: 1350px;
$breakM: 1280px;
// $breakS: 550px;
$breakS: 900px;
$breakXS: 540px;

/* The animation code */
@keyframes slide {
  0%   {left: 0%;}
  100% {left: 70%;}
}

@keyframes fadeOut1 {
  0%   {opacity: 100%;}
  100% {opacity: 0%;}
}

@keyframes claim {
  0%   {top: 600px;}
  100% {top: 404px;}
}
@keyframes claimXS {
  0%   {left: -100%;}
  100% {left: 4px;}
}
@keyframes claimXS2 {
  0%   {left: 100%;}
  100% {left: 104px;}
}

@keyframes colorChangeOrange {
  0%   {background: $orange;}
  16.66%   {background: $petrol;}
  33.33%   {background: $gelb;}
  66.66%   {background: $gruen;}
  83.33%   {background: $blau;}
  100%   {background: $violett;}
}

@font-face {
  font-family: "Minion";
  font-style: normal;
  font-weight: normal;
  src: url("../font/eot/2BF373_4_0.eot?#iefix");
  src: url("../font/eot/2BF373_4_0.eot?#iefix") format("embedded-opentype"), url("../font/woff/2BF373_4_0.woff") format("woff"), url("../font/ttf/2BF373_4_0.ttf") format("truetype");
}
@font-face {
  font-family: "Minion";
  font-style: normal;
  font-weight: bold;
  src: url("../font/eot/2BF373_14_0.eot?#iefix");
  src: url("../font/eot/2BF373_14_0.eot?#iefix") format("embedded-opentype"), url("../font/woff/2BF373_14_0.woff") format("woff"), url("../font/ttf/2BF373_14_0.ttf") format("truetype");
}
@font-face {
  font-family: "Minion";
  font-style: italic;
  font-weight: normal;
  src: url("../font/eot/2BF373_1B_0.eot?#iefix");
  src: url("../font/eot/2BF373_1B_0.eot?#iefix") format("embedded-opentype"), url("../font/woff/2BF373_1B_0.woff") format("woff"), url("../font/ttf/2BF373_1B_0.ttf") format("truetype");
}
@font-face {
  font-family: "SuperGrotesk";
  font-style: normal;
  font-weight: normal;
  src: url("../font/eot/SuperGroteskPro.eot?#iefix");
  src: url("../font/eot/SuperGroteskPro.eot?#iefix") format("embedded-opentype"), url("../font/woff/SuperGroteskPro.woff") format("woff"), url("../font/ttf/SuperGroteskPro.ttf") format("truetype");
}
@font-face {
  font-family: "SuperGrotesk";
  font-style: normal;
  font-weight: bold;
  src: url("../font/eot/SuperGroteskPro-Medium.eot?#iefix");
  src: url("../font/eot/SuperGroteskPro-Medium.eot?#iefix") format("embedded-opentype"), url("../font/woff/SuperGroteskPro-Medium.woff") format("woff"), url("../font/ttf/SuperGroteskPro-Medium.ttf") format("truetype");
}

.logoHolder{
  position:absolute;
  top: 0px;
  left: 0px;
  width:600px;
  height: 200px;
  background:#fff;
  display:none;
  @media only screen and (max-width: $breakXS) {
    width:300px;
    height: 100px;
  }
  .logo{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:500px;
    @media only screen and (max-width: $breakXS) {
      width:250px;
    }
  }
}

.colorSquare{
  width: 100px;
  height: 100px;
  display:block;
  background:#f00;
  position:absolute;
  border:solid 4px #fff;
  transition: all 1s;
  @media only screen and (max-width: $breakXS) {
    width: 50px;
    height: 50px;
  }
  &.orange{
    background:$orange;
    // animation-name: colorChangeOrange;
    // animation-duration: 6s;
    // animation-fill-mode: forwards;
    // animation-iteration-count: infinite;
  }
  &.petrol{background:$petrol;}
  &.gelb{background:$gelb;}
  &.gruen{background:$gruen;}
  &.blau{background:$blau;}
  &.violett{background:$violett;}
  &.fadeOut{
    animation-name: fadeOut1;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}

.colorIcon{
  width: 96px;
  height:96px;
  display:block;
  position:absolute;
  z-index:1000;
  background-color:#fff;
  background-size: 80px 80px;
  background-repeat:no-repeat;
  background-position:center center;
  @media only screen and (max-width: $breakXS) {
    width: 46px;
    height:46px;
    background-size: 40px 40px;
  }
  &.project{
    top: 104px;
    left:1004px;
    background-image: url('../img/projekt.svg');
    @media only screen and (max-width: $breakXS) {
      top: 154px;
      left:104px;
    }
  }
  &.designbereich{
    top: 304px;
    left:504px;
    background-image: url('../img/designbereich.svg');
    @media only screen and (max-width: $breakXS) {
      top: 204px;
      left:204px;
    }
  }
  &.objecktgruppe{
    top: 304px;
    left:4px;
    background-image: url('../img/objektgruppe.svg');
    @media only screen and (max-width: $breakXS) {
      top: 104px;
      left:304px;
    }
  }
  &.objekt{
    top: 204px;
    left:704px;
    background-image: url('../img/objekt.svg');
    @media only screen and (max-width: $breakXS) {
      top: 404px;
      left:4px;
    }
  }
  &.aufwand{
    top: 304px;
    left:904px;
    background-image: url('../img/aufwand.svg');
    @media only screen and (max-width: $breakXS) {
      top: 304px;
      left:304px;
    }
  }
}
.whiteIcon{
  width: 96px;
  height:96px;
  display:block;
  position:absolute;
  z-index:1000;
  background-size: 60px 60px;
  background-repeat:no-repeat;
  background-position:center center;
  opacity: 0.9;
  @media only screen and (max-width: $breakXS) {
    width: 46px;
    height:46px;
    background-size: 30px 30px;
  }
  &.herz{
    top: 104px;
    left:804px;
    background-image: url('../img/herz_a.svg');
    @media only screen and (max-width: $breakXS) {
      top: 204px;
      left:4px;
    }
  }
  &.help{
    top: 4px;
    left:1204px;
    background-image: url('../img/help.svg');
    @media only screen and (max-width: $breakXS) {
      top: 4px;
      left:304px;
    }
  }
  &.filter{
    top: 204px;
    left:204px;
    background-image: url('../img/filter.svg');
    @media only screen and (max-width: $breakXS) {
      top: 204px;
      left:354px;
    }
  }
  &.katalog{
    top: 404px;
    left:304px;
    background-image: url('../img/katalog.svg');
  }
}

.claim, .claim2{
  background:$darkpetrol;
  position:absolute;
  z-index: 1002;
  height: 96px;
  width: calc(100% - 700px);
  left: 704px;
  top: 504px;
  text-align:center;
  line-height:101px;
  font-family: SuperGrotesk;
  font-weight:bold;
  color:white;
  font-size:40px;
  white-space: nowrap;
  text-transform:uppercase;
  @media only screen and (max-width: $breakXL) {
    width: calc(100% - 500px);
    left: 504px;
  }
  @media only screen and (max-width: $breakL) {
    width: calc(100% - 400px);
    left: 404px;
  }
  @media only screen and (max-width: $breakM) {
    width: 100%;
    left: 0px;
  }
  @media only screen and (max-width: $breakS) {
    width: 100%;
    left: 0px;
  }
}
.claim{
  animation-name: claim;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 3s;
  animation-iteration-count: 1;
  @media only screen and (max-width: $breakXS) {
    animation-name: claimXS;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    animation-iteration-count: 1;
    width: 246px;
    font-size:20px;
    left: -100%;
    height: 46px;
    line-height:51px;
    top: 304px;
  }
  .noXS{
    @media only screen and (max-width: $breakS) {
      display:none;
    }
  }
}
.claim2{
  animation-name: claim;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 6s;
  animation-iteration-count: 1;
  text-transform:uppercase;
  @media only screen and (max-width: $breakXS) {
    animation-name: claimXS2;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 3s;
    animation-iteration-count: 1;
    width: calc(100% - 100px);
    font-size:20px;
    left: 100%;
    height: 46px;
    line-height:51px;
    top: 354px;
  }
}
a{
  color: $orange;
  text-decoration:none;
  &:hover{
    text-decoration:underline;
  }
}

html, body{
  background: #fff;
  width: 100%;
  height: 100%;
  user-select: none;
}
.bannerSpecial{
  height: 100%;
  height: 500px;
  max-width:1600px;
  width: 100%;
  top:-4px;
  position: absolute;
  overflow:hidden;
  @media only screen and (max-width: $breakXS) {
    height: calc(100vH - 200px)
  }
}
